import * as React from 'react';
import { Translation } from 'react-i18next';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.setCookie = this.setCookie.bind(this);
    }

    setCookie() {
        this.props.cookieConsentAccepted();
    }

    render() {
        return (
            <div className='cookie-consent-container'>
                <div id='cookie-consent-popup' className='hidden'>
                    <div className='box left'>
                        <Translation>{(t) => t('cookieConsent.text')}</Translation>
                    </div>
                    <div className='box right'>
                        <button className='button' onClick={this.setCookie}>OK</button>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Header;