import * as React from 'react';
import PointTarget from 'react-point'
import { isMobile } from "react-device-detect";

class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
            ...props
        }
        this.handleOnPoint = this.handleOnPoint.bind(this);
        this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
        this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
    }

    handleOnPoint() {
        if (this.state.disabled || !isMobile) {
            return;
        }
        this.toggleHover();
    }

    handleOnMouseEnter() {
        if (this.state.disabled || isMobile) {
            return;
        }
        this.toggleHover();
    }

    handleOnMouseLeave() {
        if (this.state.disabled || isMobile) {
            return;
        }
        this.toggleHover();
    }

    toggleHover() {
        this.setState({
            'isHovered': !this.state.isHovered,
        })
    }

    hoverClass() {
        return this.state.isHovered ? 'hovered' : '';
    }

    render() {
        return (
            <PointTarget onPoint={this.handleOnPoint}>
                <div className={`card ${this.state.className} ${this.hoverClass()}`} onMouseEnter={this.handleOnMouseEnter} onMouseLeave={this.handleOnMouseLeave}>
                    <div className="front">{this.props.front}</div>
                    <div className="back">{this.props.back}</div>
                </div>
            </PointTarget>
        );
    }
}
 
export default Card;