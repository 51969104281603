import * as React from 'react';
import { Link } from 'react-scroll';

class Logo extends React.Component {
    render() {
        const hiddenClass = this.props.logoOpened ? '' : 'hidden';
        return (
            <div className={`logo-container ${hiddenClass}`} onClick={this.props.handleClick}>
                <img src="static/logo.png" />
            </div>
        );
    }
}
 
export default Logo;