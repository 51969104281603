import * as React from 'react';
import Masonry from 'react-masonry-component';
 
const masonryOptions = {
    transitionDuration: 1000,
    gutter: 10,
    fitWidth: true
};
 
class Gallery extends React.Component {
    render() {
        return (
            <Masonry
                id={`gallery-${this.props.id}`}
                className={'gallery'} // default ''
                elementType={'div'} // default 'div'
                options={masonryOptions} // default {}
                disableImagesLoaded={false} // default false
                updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                imagesLoadedOptions={{}} // default {}
            >
                {this.props.items}
            </Masonry>
        );
    }
}
 
export default Gallery;