export const postcards = [
    { front: '../static/postcard/01_langos_02.jpg', back: '../static/postcard/01_langos_01.jpg' },
    { front: '../static/postcard/02_rigojancsi_02.jpg', back: '../static/postcard/02_rigojancsi_01.jpg' },
    { front: '../static/postcard/03_zserbo_02.jpg', back: '../static/postcard/03_zserbo_01.jpg' },
    { front: '../static/postcard/04_rakoczi_02.jpg', back: '../static/postcard/04_rakoczi_01.jpg' },
    { front: '../static/postcard/05_bejgli_02.jpg', back: '../static/postcard/05_bejgli_01.jpg' },
    { front: '../static/postcard/06_eszterhazy_02.jpg', back: '../static/postcard/06_eszterhazy_01.jpg' },
    { front: '../static/postcard/07_kurtos_02.jpg', back: '../static/postcard/07_kurtos_01.jpg' },
    { front: '../static/postcard/08_kremes_02.jpg', back: '../static/postcard/08_kremes_01.jpg' },
];

export const magnets = [
    { front: '../static/magnet/01_langos_01.jpg', back: '../static/magnet/01_langos_01.jpg' },
    { front: '../static/magnet/02_rigojancsi_01.jpg', back: '../static/magnet/02_rigojancsi_01.jpg' },
    { front: '../static/magnet/03_zserbo_01.jpg', back: '../static/magnet/03_zserbo_01.jpg' },
    { front: '../static/magnet/04_rakoczi_01.jpg', back: '../static/magnet/04_rakoczi_01.jpg' },
    { front: '../static/magnet/05_bejgli_01.jpg', back: '../static/magnet/05_bejgli_01.jpg' },
];

export const bags = [
    { front: '../static/bag/01_fuschia_02.jpg', back: '../static/bag/01_fuschia_01.jpg' },
    { front: '../static/bag/02_midnightblue_02.jpg', back: '../static/bag/02_midnightblue_01.jpg' },
    { front: '../static/bag/03_turquoise_02.jpg', back: '../static/bag/03_turquoise_01.jpg' },
];

export const worldcards = [
    { thumb: '../static/world/postcard/thumb/12.jpg', orig: '../static/world/postcard/orig/12.jpg' },
    { thumb: '../static/world/postcard/thumb/14.jpg', orig: '../static/world/postcard/orig/14.jpg' },
    { thumb: '../static/world/postcard/thumb/15.jpg', orig: '../static/world/postcard/orig/15.jpg' },
    { thumb: '../static/world/postcard/thumb/17x.jpg', orig: '../static/world/postcard/orig/17x.jpg' },
    { thumb: '../static/world/postcard/thumb/24.jpg', orig: '../static/world/postcard/orig/24.jpg' },
    { thumb: '../static/world/postcard/thumb/28.jpg', orig: '../static/world/postcard/orig/28.jpg' },
    { thumb: '../static/world/postcard/thumb/29.jpg', orig: '../static/world/postcard/orig/29.jpg' },
    { thumb: '../static/world/postcard/thumb/32x.jpg', orig: '../static/world/postcard/orig/32x.jpg' },
    { thumb: '../static/world/postcard/thumb/33.jpg', orig: '../static/world/postcard/orig/33.jpg' },
    { thumb: '../static/world/postcard/thumb/39.jpg', orig: '../static/world/postcard/orig/39.jpg' },
    { thumb: '../static/world/postcard/thumb/41.jpg', orig: '../static/world/postcard/orig/41.jpg' },
    { thumb: '../static/world/postcard/thumb/42.jpg', orig: '../static/world/postcard/orig/42.jpg' },
    { thumb: '../static/world/postcard/thumb/43.jpg', orig: '../static/world/postcard/orig/43.jpg' },
    { thumb: '../static/world/postcard/thumb/59.jpg', orig: '../static/world/postcard/orig/59.jpg' },
    { thumb: '../static/world/postcard/thumb/usa.jpg', orig: '../static/world/postcard/usa.jpg' },
    { thumb: '../static/world/postcard/thumb/sicilia.jpg', orig: '../static/world/postcard/orig/sicilia.jpg' },
    { thumb: '../static/world/postcard/thumb/parizs.jpg', orig: '../static/world/postcard/orig/parizs.jpg' },
    { thumb: '../static/world/postcard/thumb/london.jpg', orig: '../static/world/postcard/orig/london.jpg' },
    { thumb: '../static/world/postcard/thumb/kuba.jpg', orig: '../static/world/postcard/orig/kuba.jpg' },
    { thumb: '../static/world/postcard/thumb/cdmx.jpg', orig: '../static/world/postcard/orig/cdmx.jpg' },
    { thumb: '../static/world/postcard/thumb/budapest.jpg', orig: '../static/world/postcard/orig/budapest.jpg' },
    { thumb: '../static/world/postcard/thumb/39_190716.jpg', orig: '../static/world/postcard/orig/39_190716.jpg' },
    { thumb: '../static/world/postcard/thumb/33_190716.jpg', orig: '../static/world/postcard/orig/33_190716.jpg' },
    { thumb: '../static/world/postcard/thumb/29_190716.jpg', orig: '../static/world/postcard/orig/29_190716.jpg' },
    { thumb: '../static/world/postcard/thumb/25_190716.jpg', orig: '../static/world/postcard/orig/25_190716.jpg' },
    { thumb: '../static/world/postcard/thumb/15_190716.jpg', orig: '../static/world/postcard/orig/15_190716.jpg' },
    { thumb: '../static/world/postcard/thumb/19083101.jpg', orig: '../static/world/postcard/orig/19083101.jpg' },
    { thumb: '../static/world/postcard/thumb/19083102.jpg', orig: '../static/world/postcard/orig/19083102.jpg' },
    { thumb: '../static/world/postcard/thumb/19083103.jpg', orig: '../static/world/postcard/orig/19083103.jpg' },
    { thumb: '../static/world/postcard/thumb/budapest_2004201012.jpg', orig: '../static/world/postcard/orig/budapest_2004201012.jpg' },
    { thumb: '../static/world/postcard/thumb/erice_2004201012.jpg', orig: '../static/world/postcard/orig/erice_2004201012.jpg' },
    { thumb: '../static/world/postcard/thumb/lisboa_2004201012.jpg', orig: '../static/world/postcard/orig/lisboa_2004201012.jpg' },
    { thumb: '../static/world/postcard/thumb/madrid_2004201012.jpg', orig: '../static/world/postcard/orig/madrid_2004201012.jpg' },
    { thumb: '../static/world/postcard/thumb/malmo_2004201012.jpg', orig: '../static/world/postcard/orig/malmo_2004201012.jpg' },
    { thumb: '../static/world/postcard/thumb/new_york_2004201012.jpg', orig: '../static/world/postcard/orig/new_york_2004201012.jpg' },
    { thumb: '../static/world/postcard/thumb/porto_2004201012.jpg', orig: '../static/world/postcard/orig/porto_2004201012.jpg' },
    { thumb: '../static/world/postcard/thumb/roma_2_2004201012.jpg', orig: '../static/world/postcard/orig/roma_2_2004201012.jpg' },
    { thumb: '../static/world/postcard/thumb/santiago_2004201012.jpg', orig: '../static/world/postcard/orig/santiago_2004201012.jpg' },
    { thumb: '../static/world/postcard/thumb/stilbaai_2004201012.jpg', orig: '../static/world/postcard/orig/stilbaai_2004201012.jpg' },
    { thumb: '../static/world/postcard/thumb/vianden_2004201012.jpg', orig: '../static/world/postcard/orig/vianden_2004201012.jpg' },
];
