import * as React from 'react';
import ModalImage from 'react-modal-image';
import { Translation } from 'react-i18next';
import Header from './Header';
import HeaderMobile from './HeaderMobile';
import Logo from './Logo';
import Gallery from './Gallery';
import Card from './Card';
import { worldcards } from '../database';
import { versionNumber } from '../constant';
import Postcards from './Postcards';
import Bags from './Bags';

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            logoOpened: props.logoOpened,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            logoOpened: nextProps.logoOpened,
        });
    }

    renderFlippingCards(cards, typeClass, isDisabled) {
        let disabled = (isDisabled === true) ? true : false;
        return cards.map((card, index) => {
            return (
                <Card 
                    key={index}
                    className={`item ${typeClass}`}
                    disabled={disabled}
                    front={<img width='300' src={`${card.front}`} />}
                    back={<img width='300' src={`${card.back}`} />}
                />
            );
        });
    }
    
    renderModalImageCards(cards) {
        return cards.map((card, index) => {
            return (
                <div className={'item'} key={index}>
                    <ModalImage
                        className={'thumb'}
                        small={card.thumb}
                        large={card.orig}
                    />
                </div>
            );
        });
    }

    hideMainLogo() {
        this.props.hideLogo();
    }

    renderLogo() {
        return (
            <div className="app">
                <div id='main-logo' className="container">
                    <div className="content">
                        <Logo 
                            logoOpened={this.state.logoOpened}
                            handleClick={this.hideMainLogo.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderPage() {
        return (
            <div className="app">
                <div className="container">
                    <div id={`header-container`} className={`content`}>
                        <Header />
                        <HeaderMobile />
                    </div>
                </div>
                <div id='welcome' className="container hidden">
                    <div className="content">
                        <img src='../static/borito.jpg' width="100%" />
                        <Translation>{(t) => 
                            <p>
                            {t('welcome.part1')}<br /><br />
                            {t('welcome.part2')}<br />
                            {t('welcome.part3')}<br /><br />
                            {t('welcome.part4')}
                            </p>}
                        </Translation>
                    </div>
                </div>
                <div id='postcards' className="container hidden">
                    <div className="content">
                        <Postcards 
                            renderFlippingCards={this.renderFlippingCards}
                        />
                    </div>
                </div>
                <div id='magnets' className="container hidden">
                    <div className="content">
                        <Translation>{(t) => <h1>•&nbsp;&nbsp;{t('headers.magnet')}&nbsp;&nbsp;•</h1>}</Translation>
                        <img className={`looped-gif`} src='../static/video/magnes.gif' />
                    </div>
                </div>
                <div id='bags' className="container hidden">
                    <div className="content">
                        <Bags 
                            renderFlippingCards={this.renderFlippingCards}
                        />
                    </div>
                </div>
                <div id='world' className="container hidden">
                    <div className="content">
                        <Translation>{(t) => <h1>•&nbsp;&nbsp;{t('headers.world')}&nbsp;&nbsp;•</h1>}</Translation>
                        <img src='../static/map_world_2004201012.jpg' width="100%" />
                        <Gallery 
                            id={`world`}
                            items={this.renderModalImageCards(worldcards)}
                        />
                    </div>
                </div>
                <div id='shops' className='container'>
                    <div className="content">
                        <Translation>{(t) => <h1>•&nbsp;&nbsp;{t('headers.shop')}&nbsp;&nbsp;•</h1>}</Translation>
                        <div className={`shop-list-container`}>
                            <div className={`left`}>
                                <a href="https://www.facebook.com/Balatoni-bubor%C3%A9k-453329558453744/" target="_blank"><img src="../static/arrow-right.svg" />Balatoni Buborék • Tihany</a><br /> 
                                <a href="https://www.facebook.com/buborekbolt" target="_blank"><img src="../static/arrow-right.svg" />Buborék • Budapest</a><br /> 
                                <a href="https://www.facebook.com/CBLBudapest/" target="_blank"><img src="../static/arrow-right.svg" />Cotton Ball Lights &amp; Classhome Design Shop • Budapest</a><br /> 
                                <a href="https://www.facebook.com/magmaplus.hu/" target="_blank"><img src="../static/arrow-right.svg" />Magma+ Hungarian Art&amp;Design • Budapest</a><br /> 
                                <a href="https://www.facebook.com/minimabolt/" target="_blank"><img src="../static/arrow-right.svg" />MINIMA design shop • Győr</a><br /> 
                                <a href="https://pallasathenekiado.hu/" target="_blank"><img src="../static/arrow-right.svg" />Pallas Athéné Books • Budapest</a><br />
                                <a href="https://www.facebook.com/sziaplus/" target="_blank"><img src="../static/arrow-right.svg" />SZIA+ • Budapest</a><br /> 
                                <a href="https://youniqstore.hu/shop/" target="_blank"><img src="../static/arrow-right.svg" />You:niq &amp; hoom • Budapest</a><br /> 
                                <a>Ajándék Terminál • pop up store • Budapest</a><br />
                                <a>Ajándék Terminál @ Sziget Fesztivál • pop up store • Budapest</a><br />
                                <a>Projekt Showroom • pop up store • Budapest</a>
                            </div>
                            <div className={`right`}>
                                <a href="mailto:hellotastea@gmail.com">
                                    <img src="../static/reka_pinter_tastea.jpg" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='social' className='container'>
                    <div className="content">
                        <div className={`social-container`}>
                            <div className={`left`}>
                                <a href="https://www.facebook.com/hello.taste.a/" target="_blank">
                                    <img src={`../static/facebook.png?r=${versionNumber}`} />
                                </a>
                            </div>
                            <div className={`middle`}>
                                <a href="https://www.instagram.com/_taste_a_/" target="_blank">
                                    <img src={`../static/instagram.png?r=${versionNumber}`} />
                                </a>
                            </div>
                            <div className={`right`}>
                                <a href="mailto:hellotastea@gmail.com">
                                    <img src={`../static/gmail.png?r=${versionNumber}`} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.logoOpened) {
            return this.renderLogo();
        }
        return this.renderPage();
    }
}
 
export default Page;