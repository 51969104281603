import React, { Suspense } from 'react';
import Cookies from 'universal-cookie';
import Page from './components/Page';
import CookieConsent from './components/CookieConsent';
import { getCookieName } from './helpers/CookieHelper';
import './asset/css/App.scss';

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <img src={`../static/logo.png`} className="App-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        cookies: new Cookies(),
        logoOpened: true,
        gaInited: false,
        cookieConsentAccepted: false,
    };
  }
  
  componentDidMount() {
    const cookieConsentAccepted = (this.state.cookies.get(getCookieName()) !== undefined);
    let gaInited = this.state.gaInited;
    if (cookieConsentAccepted && !gaInited) {
      this.initGA();
      gaInited = true;
    }
    this.setState({
      cookieConsentAccepted,
      gaInited
    });
  }

  hideLogo() {
    this.setState({
      logoOpened: false
    });
  }

  initGA() {
    if (process.env.REACT_APP_ENV === "production") {
      window.initGA();
    }
  }

  cookieConsentAccepted() {
    this.initGA();
    this.state.cookies.set(getCookieName(), 'accepted', {
      path: '/'
    });
    this.setState({
      cookieConsentAccepted: true,
      gaInited: true
    });
  }

  renderCookieConsent() {
    if (this.state.cookieConsentAccepted || this.state.logoOpened) {
      return;
    }
    return (
      <CookieConsent 
        logoOpened={this.state.logoOpened}
        hideLogo={this.hideLogo.bind(this)}
        cookieConsentAccepted={this.cookieConsentAccepted.bind(this)}
      />
    );
  }

  render() {
    return (
        <Suspense fallback={<Loader />}>
            <Page 
                logoOpened={this.state.logoOpened}
                hideLogo={this.hideLogo.bind(this)}
            />
            {this.renderCookieConsent()}
        </Suspense>
    );
  }
}

export default App;
