import * as React from 'react';
import { Translation } from 'react-i18next';
import Gallery from './Gallery';
import { postcards } from '../database';

class Postcards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            ...props
        }
    }

    toggleOpen() {
        this.setState({
            open: !this.state.open
        });
    }

    renderGallery() {
        if (this.state.open) {
            return (
                <div>
                    <Gallery 
                        id={`postcards`}
                        items={this.props.renderFlippingCards(postcards, 'postcard')}
                    />
                    <div className={`flex row centered`}>
                        <div className={`arrow-up`} onClick={this.toggleOpen.bind(this)}></div>
                    </div>
                </div>
            );
        }
        return (
            <div className={`flex row centered`}>
                <div className={`arrow-down`} onClick={this.toggleOpen.bind(this)}></div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Translation>{(t) => <h1>•&nbsp;&nbsp;{t('headers.postcard')}&nbsp;&nbsp;•</h1>}</Translation>
                <img className={`looped-gif`} src='../static/video/kezek_02_vid_02.gif' />
                {this.renderGallery()}
            </div>
        );
    }
}
 
export default Postcards;