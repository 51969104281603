import * as React from 'react';
import { Link } from 'react-scroll';
import { Translation } from 'react-i18next';
import i18n from '../i18n';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hiddenClass: window.innerWidth > 930 ? '' : 'hidden'
        }
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    changeLanguage(e) {
        i18n.changeLanguage(e.target.dataset.lan);
    }

    render() {
        return (
            <div id={`header`} className={`header ${this.state.hiddenClass}`}>
                <div className={`minilogo`}>
                    <Link 
                        to="welcome"
                        smooth={true}
                        duration={1000}
                    >
                        <img src={`static\\logo.png`} />
                    </Link>
                </div>
                <div className={`menu`}>
                    <h1 className={`dot`}>•</h1>
                    <Link 
                        to="postcards"
                        smooth={true}
                        duration={1000}
                    >
                        <Translation>{(t) => <h1 className={`text`}>{t('menu.cookie')}</h1>}</Translation>
                    </Link>
                    <h1 className={`dot`}>•</h1>
                    <Link 
                        to="world"
                        smooth={true}
                        duration={1000}
                    >
                        <Translation>{(t) => <h1 className={`text`}>{t('menu.world')}</h1>}</Translation>
                    </Link>
                    <h1 className={`dot`}>•</h1>
                    <Link 
                        to="shops"
                        smooth={true}
                        duration={1000}
                    >
                        <Translation>{(t) => <h1 className={`text`}>{t('menu.shop')}</h1>}</Translation>
                    </Link>
                    <h1 className={`dot`}>•</h1>
                </div>
                <div className={`languages`}>
                    <h1 className={`text`} data-lan={`en`} onClick={this.changeLanguage}>EN</h1>
                    <h1 className={`dot`}>|</h1>
                    <h1 className={`text`} data-lan={`hu`} onClick={this.changeLanguage}>HU</h1>
                </div>
            </div>
            
        );
    }
}
 
export default Header;