import * as React from 'react';
import { Link } from 'react-scroll';
import { Translation } from 'react-i18next';
import i18n from '../i18n';
import { versionNumber } from '../constant';

class HeaderMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            hiddenClass: window.innerWidth <= 930 ? '' : 'hidden'
        };
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    toggleOpen() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    changeLanguage(e) {
        i18n.changeLanguage(e.target.dataset.lan);
    }

    render() {
        const hamburgerIcon = this.state.isOpen ? `static\\mark-x.png` : `static\\hamburger.png`;
        const openClass = this.state.isOpen ? "open" : "";
        return (
            <div id={`header-mobile`} className={`header-mobile ${this.state.hiddenClass} ${openClass}`}>
                <div className={`line main`}>
                    <div className={`left`}>
                        <div className={`minilogo`}>
                            <Link 
                                to="welcome"
                                smooth={true}
                                duration={1000}
                            >
                                <img src={`static\\logo.png?r=${versionNumber}`} />
                            </Link>
                        </div>
                    </div>
                    <div className={`middle`}>
                    <div className={`languages`}>
                        <h1 className={`text`} data-lan={`en`} onClick={this.changeLanguage}>EN</h1>
                        <h1 className={`dot`}>|</h1>
                        <h1 className={`text`} data-lan={`hu`} onClick={this.changeLanguage}>HU</h1>
                    </div>
                    </div>
                    <div className={`right`}>
                        <div className={`hamburger`} onClick={this.toggleOpen.bind(this)}>
                            <img className={`${openClass}`} src={`${hamburgerIcon}?${versionNumber}`} />
                        </div>
                    </div>
                </div>
                <Link 
                    to="postcards"
                    smooth={true}
                    duration={1000}
                    onClick={this.toggleOpen.bind(this)}
                >
                    <div className={`line menu`}>
                        <Translation>{(t) => <h1 className={`text`}>{t('menu.cookie')}</h1>}</Translation>
                    </div>
                </Link>
                <Link 
                    to="world"
                    smooth={true}
                    duration={1000}
                    onClick={this.toggleOpen.bind(this)}
                >
                    <div className={`line menu`}>
                        <Translation>{(t) => <h1 className={`text`}>{t('menu.world')}</h1>}</Translation>
                    </div>
                </Link>
                <Link 
                    to="shops"
                    smooth={true}
                    duration={1000}
                    onClick={this.toggleOpen.bind(this)}
                >
                    <div className={`line menu`}>
                        <Translation>{(t) => <h1 className={`text`}>{t('menu.shop')}</h1>}</Translation>
                    </div>
                </Link>
            </div>
        );
    }
}
 
export default HeaderMobile;